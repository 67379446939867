import React from 'react';
import { useTranslation } from 'react-i18next';
import DocumentTitle from 'react-document-title';
import { ApplicationName } from 'Config';

interface Props {
    title: string;
    children?: any;
}

const ScreenTitle: React.FunctionComponent<Props> = ({ children, title }: Props) => {
    const { t } = useTranslation();

    return (
        <DocumentTitle title={ (title ? (title + ' - ') : '') + ApplicationName}>
            <>{children}</>
        </DocumentTitle>
    );
}

export default ScreenTitle;
