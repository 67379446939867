import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { FaBars } from 'react-icons/fa';
import styles from './Sidebar.module.scss';
import colors from 'styles/export/colors.module.scss';
import { POLICIES } from 'Config';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import UsersService from 'api/users/UsersService';
import { menuItemStyles } from './components/styles/styles';
import i18n from 'common/services/I18n';
import { Menus } from './components/Menus';
import Events from 'common/services/Events';

export interface SideBarItem {
    label: string;
    url?: string;
    Icon?: any;
    policies: (keyof typeof POLICIES)[];
    type?: 'OR' | 'AND';
    items?: SideBarItem[];
    itemsLoader?: () => Promise<SideBarItem[]>;
}

const SideBar: React.FunctionComponent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = React.useState(false);
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    const [menuItems, setMenuItems] = useState<SideBarItem[]>(Menus);
    const isComponentMounted = useRef(false);

    useEffect(() => {
        isComponentMounted.current = true;

        const sideBarUpdateEvent = Events.addListener('side-bar-update', () => {
            if (isComponentMounted.current) {
                void loadItems();
            }
        });

        return () => {
            isComponentMounted.current = false;
            sideBarUpdateEvent.remove();
        };
    }, []);

    const loadItems = async () => {
        const loadedMenus = await Promise.all(
            Menus.map(async (item) => {
                if (item.itemsLoader) {
                    const loadedSubItems = await item.itemsLoader();
                    return { ...item, items: [...loadedSubItems, ...item.items!] };
                }
                return item;
            })
        );
        setMenuItems(loadedMenus);
    };

    useEffect(() => {
        void loadItems();
    }, []);

    const validateItem = useCallback((item: SideBarItem) => {
        const type = item.type ?? 'AND';
        const policies = item.policies ?? [];
        const userPolicies = loggedUser?.policies ?? [];
        return item.policies.length > 0 ? UsersService.hasPolicies(userPolicies, policies, type) : true;
    }, [loggedUser]);

    const sidebarItems: SideBarItem[] = useMemo(() => {
        return menuItems.filter(item => validateItem(item))
            .map(item => { item.items = item?.items ? item.items.filter(i => validateItem(i)) : undefined; return item; });
    }, [loggedUser, menuItems, validateItem, i18n.language]);

    const renderMenus = () => {
        return sidebarItems.map((item, index) => {
            if (item.items?.length) {
                return <SubMenu
                    key={`submenu_${item.url}`}
                    label={item.label}
                    icon={item.Icon ? <item.Icon /> : undefined}>
                    {renderSubMenus(item.items)}
                </SubMenu>
            }
            return renderMenuItem(item, `menuitem_${index}`)
        });
    }

    const renderSubMenus = (items: SideBarItem[] | undefined) => {
        return items?.map((item, index) => renderMenuItem(item, `submenuitem_${index}`));
    }

    const onMenuClick = (e: any, item: SideBarItem) => {
        e.preventDefault();
        e.stopPropagation();
        if (item.url) {
            navigate(item.url)
        }
    }

    const renderMenuItem = (item: SideBarItem, key: string) => (
        <MenuItem
            href={item.url}
            key={key}
            active={location.pathname.includes(item.url!)}
            icon={item.Icon ? <item.Icon /> : undefined}
            onClick={(e) => onMenuClick(e, item)}>
            {item.label}
        </MenuItem>
    )

    return (
        <Sidebar className={styles.sidebar} collapsed={collapsed} rootStyles={{ overflow: 'visible' }}>
            <div className={styles.logoContainer} onClick={() => setCollapsed(!collapsed)}>
                <img src="/images/logomini.svg" width={40} />
                <FaBars color={colors.primary} />
            </div>
            <Menu menuItemStyles={menuItemStyles}>
                {renderMenus()}
            </Menu>
        </Sidebar>
    );
};

export default SideBar;
