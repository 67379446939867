import styles from './NavBar.module.scss';
import { Reducers } from 'store/types';
import { UserProfile } from 'api/account/models/UserProfile';
import { ApplicationPaths, LOGGER_LOG_TYPE, DEFAULT_LANGUAGE, ApplicationName } from 'Config';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import LanguagesService from 'api/languages/LanguagesService';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import AccountService from 'api/account/AccountService';
import { setProfile } from 'store/authentication/action';
import Logger from 'common/services/Logger';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaRegUser, FaSignOutAlt } from 'react-icons/fa';
import { TbLogout } from 'react-icons/tb';
import { listLanguages } from 'common/services/I18n';

export const NAVBAR_BREADCRUMBS_ID = 'navbarBreadcrumbsId';

export const NavBar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const loggedUser = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const [langs, setLangs] = useState<any[]>([]);
    const [lang, setLang] = useState<any>([]);

    const changeLang = (lang: SelectValueLabel) => {
        AccountService.changeLang(lang)
            .finally(() => {
                getProfile().catch(console.error);
                setLang(lang);
            });
    }

    useEffect(() => {
        LanguagesService.GetAllForSelectItem()
            .then((data) => {
                setLangs(data);
                setLang(data.filter(l => l.value === loggedUser?.languageId)[0]);
                const browserLanguage = window.navigator.language.toLowerCase().split('-')[0];
                const langByBrowser = listLanguages.find(x => browserLanguage.includes(x.toLowerCase()));
                const lang = data.find(x => x.label === langByBrowser) ?? data.find(x => x.label === DEFAULT_LANGUAGE)!
                changeLang(lang)
            })
            .catch(() => Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get languages'));
    }, []);

    const getProfile = async () => {
        try {
            const profile = await AccountService.profile();
            dispatch(setProfile(profile) as any);
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get user profile', error);
        }
    }

    return (
        <div className={styles.container}>
            <div className='wrapped-container'>
                <div className={styles.appName}>
                    {ApplicationName}
                </div>
                <div className={styles.bottom}>
                    <div className={styles.breadcrumbs} id={NAVBAR_BREADCRUMBS_ID}></div>
                    <div className={styles.userLinks}>
                        <a href="#"><FaRegUser /> <span>{loggedUser?.realName}</span></a>
                        <Link to={ApplicationPaths.LogOut}><TbLogout fontSize={22} /></Link>
                    </div>
                </div>
            </div>
        </div>
    );

    // return (
    //     <Navbar className={`main-header ${styles.navBar}`} data-bs-theme="dark">
    //         <Container fluid className="justify-content-end">
    //             <Navbar.Collapse style={{ flexGrow: 'unset', padding: '0 10px' }}>
    //                 <Nav>
    //                     <NavDropdown title={loggedUser?.realName} menuVariant="dark">
    //                         <NavDropdown.Item onClick={() => navigate('/account/details')}>{t('common.profile')}</NavDropdown.Item>
    //                         <NavDropdown.Item onClick={() => navigate(`${ApplicationPaths.LogOut}`)}>{t('common.logout')}</NavDropdown.Item>
    //                     </NavDropdown>
    //                 </Nav>
    //             </Navbar.Collapse>
    //             {lang && <Navbar.Collapse style={{ flexGrow: 'unset', padding: '0 10px' }}>
    //                 <Nav>
    //                     <NavDropdown title={lang?.fullLabel} menuVariant="dark">
    //                         {langs.map(l => {
    //                             return <NavDropdown.Item onClick={() => changeLang(l)} key={l.value}>{l.fullLabel}</NavDropdown.Item>
    //                         })}
    //                     </NavDropdown>
    //                 </Nav>
    //             </Navbar.Collapse>
    //             }
    //             <Navbar.Brand></Navbar.Brand>
    //         </Container>
    //     </Navbar>
    // );
};
